import type { SVGProps } from "react";

export function Canton(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={`dark:border dark:border-gray-700 ${props.className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="canton-icon"
    >
      <circle cx="10" cy="10" r="10" fill="#272727" />
      <path
        d="M15.5835 7.3489C14.9195 7.6754 14.2565 8.0009 13.606 8.3204C13.379 8.0729 13.181 7.8239 12.95 7.6119C12.4885 7.1894 11.926 6.9599 11.3155 6.8629C10.483 6.7304 9.65496 6.7639 8.84096 6.9989C7.32346 7.4364 6.46896 8.6989 6.62196 10.2739C6.78146 11.9164 7.79546 12.9324 9.49595 13.1484C10.228 13.2414 10.9565 13.2474 11.679 13.0604C12.4265 12.8669 13.0245 12.4579 13.482 11.8394C13.526 11.7799 13.571 11.7214 13.621 11.6554C14.2855 11.9859 14.9395 12.3109 15.6185 12.6484C15.3475 13.0759 15.0155 13.4174 14.6395 13.7189C13.613 14.5429 12.424 14.9634 11.127 15.0729C9.55495 15.2054 8.02696 15.0369 6.64096 14.2084C5.24196 13.3719 4.52896 12.1039 4.39796 10.4954C4.31346 9.4619 4.45446 8.4649 4.95096 7.5424C5.52696 6.4729 6.42546 5.7604 7.55596 5.3679C9.41895 4.7214 11.294 4.6764 13.1505 5.4094C14.049 5.7644 14.8135 6.3169 15.416 7.0809C15.4625 7.1399 15.506 7.2009 15.5485 7.2624C15.5615 7.2814 15.567 7.3064 15.5845 7.3494L15.5835 7.3489Z"
        fill="#F3FF97"
      />
    </svg>
  );
}
