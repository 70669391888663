import type { SVGProps } from "react";

export function Tezos(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="tezos-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#0D61FF" />
      <path
        d="M16.9968 15.3285C16.8518 12.7574 13.6246 11.5887 13.3157 11.4687C13.3031 11.4624 13.3031 11.4498 13.3094 11.4371L16.6375 8.08906V7.72899C16.6356 7.67024 16.589 7.62349 16.5303 7.6216H10.3974V5.60644V5L8.13457 5.47378V5.81491H8.26063C8.26063 5.81491 8.8153 5.81491 8.8153 6.37081V7.61528H7.06303C7.02899 7.61781 7.00252 7.64434 7 7.67845V8.46809H8.82161V12.7574C8.82161 14.1029 9.68514 15.0379 11.1979 14.9115C11.5093 14.8806 11.8087 14.7719 12.0677 14.5957C12.1818 14.5224 12.2505 14.3967 12.2505 14.2609V13.8376C11.7589 14.1661 11.3429 14.1472 11.3429 14.1472C10.3848 14.1472 10.4037 12.928 10.4037 12.928V8.46809H14.8159L11.6391 11.6772C11.6328 12.1004 11.6265 12.4289 11.6265 12.4352C11.6252 12.4447 11.6322 12.4529 11.6416 12.4542C11.6429 12.4542 11.6442 12.4542 11.6454 12.4542C14.5575 12.9469 15.3454 14.8357 15.3454 15.3853C15.3454 15.3853 15.6605 18.0574 12.9943 18.2406C12.9943 18.2406 11.2483 18.3164 10.9395 17.6152C10.9281 17.5925 10.9382 17.5647 10.9609 17.554C10.9622 17.5533 10.9634 17.5527 10.9647 17.5521C11.2616 17.4276 11.4538 17.1358 11.45 16.813C11.45 16.295 11.1349 15.8717 10.473 15.8717C9.93726 15.8717 9.49604 16.295 9.49604 16.813C9.49604 16.813 9.24392 19.0619 12.988 18.9987C17.2615 18.9229 16.9968 15.3285 16.9968 15.3285Z"
        fill="white"
      />
    </svg>
  );
}
