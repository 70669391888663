import type { SVGProps } from "react";

export function NEAR(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="near-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#00EC97" />
      <path
        d="M16.7214 6C16.277 6 15.8643 6.22967 15.6315 6.60722L13.1232 10.319C13.0415 10.4414 13.0746 10.6063 13.1974 10.6877C13.2969 10.7538 13.4285 10.7456 13.5191 10.6679L15.9881 8.53348C16.0291 8.49668 16.0923 8.50043 16.1293 8.54132C16.146 8.56006 16.1549 8.58425 16.1549 8.60913V15.2919C16.1549 15.3471 16.1101 15.3914 16.0547 15.3914C16.025 15.3914 15.997 15.3785 15.9782 15.3557L8.5148 6.45116C8.27173 6.16526 7.91482 6.00034 7.5391 6H7.27825C6.57229 6 6 6.57042 6 7.27407V16.6863C6 17.3899 6.57229 17.9603 7.27825 17.9603C7.72269 17.9603 8.13532 17.7307 8.36814 17.3531L10.8765 13.6413C10.9581 13.519 10.925 13.3541 10.8022 13.2726C10.7028 13.2065 10.5712 13.2147 10.4806 13.2924L8.01156 15.4269C7.97054 15.4637 7.9073 15.4599 7.87038 15.419C7.85362 15.4003 7.84473 15.3761 7.84507 15.3512V8.66672C7.84507 8.61151 7.88986 8.56722 7.94524 8.56722C7.97464 8.56722 8.00302 8.58016 8.02182 8.60299L15.4842 17.5092C15.7272 17.7951 16.0842 17.96 16.4599 17.9603H16.7207C17.4267 17.9607 17.9993 17.3906 18 16.687V7.27407C18 6.57042 17.4273 6 16.7214 6Z"
        fill="black"
      />
    </svg>
  );
}
