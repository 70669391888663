import type { SVGProps } from "react";

export function Hedera(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <circle
        cx="32"
        cy="32"
        r="32"
        className="fill-[#222222] dark:fill-[#F9F9F9]"
      />
      <path
        d="M45.016 46.016H40.952V37.376H23.064V46.016H19V18H23.064V26.432H40.952V18H45.016V46.016ZM23.256 34.144H41.144V29.68H23.256V34.144Z"
        className="fill-[#F9F9F9] dark:fill-[#222222]"
      />
    </svg>
  );
}
