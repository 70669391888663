import type { SVGProps } from "react";

export function Arbitrum(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={`dark:border dark:border-gray-700 ${props.className}`}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="solana-icon"
    >
      <circle
        cx="16"
        cy="16"
        r="16"
        className="fill-[#000000] dark:fill-[#ffffff]"
      />
      <path
        d="M7.04831 11.7116V20.287C7.04831 20.8345 7.34084 21.3408 7.81474 21.6136L15.2412 25.9022C15.7151 26.175 16.2986 26.175 16.7725 25.9022L24.199 21.6136C24.6729 21.3408 24.9655 20.8345 24.9655 20.287V11.7116C24.9655 11.1641 24.6729 10.6578 24.199 10.385L16.7725 6.09641C16.2986 5.82357 15.7151 5.82357 15.2412 6.09641L7.81295 10.385C7.33905 10.6578 7.04831 11.1641 7.04831 11.7116Z"
        fill="#213147"
      />
      <path
        d="M17.6271 17.6625L16.5679 20.567C16.5393 20.6477 16.5393 20.7358 16.5679 20.8165L18.3899 25.8141L20.4973 24.597L17.9679 17.6625C17.9105 17.5028 17.6844 17.5028 17.6269 17.6625H17.6271Z"
        fill="#12AAFF"
      />
      <path
        d="M19.7508 12.7779C19.6933 12.6182 19.4672 12.6182 19.4097 12.7779L18.3506 15.6824C18.3219 15.7631 18.3219 15.8512 18.3506 15.932L21.3358 24.1142L23.4432 22.8971L19.7506 12.7779H19.7508Z"
        fill="#12AAFF"
      />
      <path
        d="M16.0061 6.41778C16.0582 6.41778 16.1103 6.4321 16.1568 6.45736L24.1936 11.0977C24.2869 11.1516 24.3444 11.252 24.3444 11.358V20.637C24.3444 20.7448 24.2869 20.8434 24.1936 20.8973L16.1568 25.5377C16.1119 25.5645 16.0582 25.5772 16.0061 25.5772C15.954 25.5772 15.9019 25.5629 15.8553 25.5377L7.81852 20.9009C7.72525 20.847 7.66778 20.7466 7.66778 20.6406V11.3598C7.66778 11.252 7.72525 11.1534 7.81852 11.0995L15.8553 6.45914C15.9021 6.4323 15.954 6.41778 16.0061 6.41778ZM16.0061 5.0625C15.7207 5.0625 15.4333 5.13608 15.1768 5.28503L7.14178 9.92361C6.6283 10.2197 6.3125 10.7674 6.3125 11.3596V20.6386C6.3125 21.231 6.6285 21.7785 7.14178 22.0746L15.1786 26.715C15.4353 26.8621 15.7207 26.9375 16.0079 26.9375C16.295 26.9375 16.5806 26.8639 16.8372 26.715L24.874 22.0746C25.3874 21.7785 25.7033 21.2308 25.7033 20.6386V11.3596C25.7033 10.7672 25.3872 10.2197 24.874 9.92361L16.8354 5.28503C16.5786 5.13608 16.2915 5.0625 16.0061 5.0625Z"
        fill="#9DCCED"
      />
      <path
        d="M10.6908 24.1249L11.4304 22.1001L12.9185 23.3368L11.5272 24.6078L10.6908 24.1249Z"
        fill="#213147"
      />
      <path
        d="M15.3293 10.6956H13.292C13.1394 10.6956 13.003 10.7907 12.9509 10.9344L8.58337 22.9078L10.6908 24.1249L15.5 10.9398C15.5449 10.8213 15.4568 10.6956 15.3293 10.6956Z"
        fill="white"
      />
      <path
        d="M18.8944 10.6956H16.857C16.7045 10.6956 16.5681 10.7907 16.516 10.9344L11.5292 24.6062L13.6366 25.8232L19.0651 10.9398C19.1082 10.8213 19.0201 10.6956 18.8944 10.6956Z"
        fill="white"
      />
    </svg>
  );
}
