import type { SVGProps } from "react";

export function TON(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="ton-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#0098EA" />
      <path
        d="M16.0971 6.69751H7.90185C6.3951 6.69751 5.44035 8.32276 6.1986 9.63751L11.2559 18.4035C11.5859 18.9758 12.4131 18.9758 12.7431 18.4035L17.8019 9.63676C18.5594 8.32501 17.6039 6.69751 16.0979 6.69751H16.0971ZM11.2521 15.774L10.1504 13.6425L7.49235 8.88901C7.45197 8.81801 7.43103 8.73761 7.43166 8.65593C7.43228 8.57426 7.45444 8.49419 7.49591 8.42381C7.53737 8.35344 7.59667 8.29525 7.66781 8.25512C7.73896 8.21499 7.81943 8.19434 7.9011 8.19526H11.2499V15.7755L11.2514 15.774H11.2521ZM16.5044 8.88826L13.8479 13.6433L12.7461 15.774V8.19376H16.0964C16.4639 8.19376 16.6799 8.58376 16.5044 8.88826Z"
        fill="white"
      />
    </svg>
  );
}
