import type { SVGProps } from "react";

export function Stellar(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="stellar-icon"
    >
      <circle
        cx="10"
        cy="10"
        r="10"
        className="fill-[#0f0f0f] dark:fill-[#f6f7f8]"
      />
      <path
        d="M5.53203 10.577C5.50689 10.385 5.49424 10.1916 5.49416 9.99805C5.49518 9.22562 5.69777 8.46685 6.08186 7.79691C6.46594 7.12697 7.01821 6.5691 7.68394 6.17856C8.34968 5.78802 9.10578 5.57837 9.87736 5.57036C10.6489 5.56236 11.4092 5.75628 12.0829 6.13292L13.0956 5.61627C12.2883 5.0297 11.3347 4.678 10.3402 4.60003C9.34575 4.52206 8.34909 4.72087 7.46041 5.17448C6.57173 5.62809 5.82563 6.31883 5.30459 7.17037C4.78354 8.02191 4.50783 9.00109 4.50794 9.99968C4.50794 10.1376 4.51317 10.275 4.52363 10.4118C4.53827 10.6056 4.49534 10.7994 4.40024 10.9688C4.30513 11.1382 4.16209 11.2756 3.98913 11.3639L3.52441 11.6011V12.7075L16.3113 6.18491V5.07905L5.53203 10.577Z"
        className="fill-[#f6f7f8] dark:fill-[#0f0f0f]"
      />
      <path
        d="M16.3113 7.29179L6.21747 12.4366L3.52441 13.8111V14.9164L14.3064 9.41688C14.3315 9.6101 14.3442 9.80474 14.3442 9.9996C14.3438 10.7729 14.1412 11.5327 13.7568 12.2034C13.3723 12.8742 12.8193 13.4326 12.1526 13.8233C11.4859 14.2139 10.7287 14.4233 9.95624 14.4304C9.18376 14.4376 8.42284 14.2424 7.74902 13.8642L7.69492 13.8929L6.73953 14.3803C7.54665 14.9669 8.50005 15.3187 9.49439 15.3968C10.4887 15.475 11.4853 15.2765 12.374 14.8232C13.2627 14.3699 14.0089 13.6795 14.5301 12.8282C15.0514 11.977 15.3274 10.998 15.3278 9.9996C15.3278 9.86042 15.3224 9.72124 15.3121 9.58368C15.2975 9.38997 15.3403 9.19626 15.4353 9.02687C15.5303 8.85748 15.6732 8.71997 15.846 8.63161L16.3113 8.39441V7.29179Z"
        className="fill-[#f6f7f8] dark:fill-[#0f0f0f]"
      />
    </svg>
  );
}
