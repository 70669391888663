import type { SVGProps } from "react";

export function Stacks(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="stacks-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#FC6432" />
      <path
        d="M14.341 9.9085C14.2951 9.83028 14.3017 9.73251 14.3542 9.65429L16.5479 6.42778C16.607 6.33653 16.6136 6.22572 16.5611 6.13447C16.5085 6.03669 16.41 5.98455 16.3049 5.98455H15.4511C15.3591 5.98455 15.2672 6.03017 15.208 6.11491L12.6465 9.89547C12.5808 9.99324 12.4757 10.0454 12.3575 10.0454H12.0356C11.9174 10.0454 11.8123 9.98672 11.7466 9.89547L9.1982 6.10839C9.14565 6.02365 9.04713 5.97803 8.95518 5.97803H8.10132C7.99623 5.97803 7.89114 6.03669 7.84516 6.13447C7.79262 6.23224 7.80575 6.34305 7.8583 6.42778L10.0521 9.66081C10.1046 9.73251 10.1112 9.83028 10.0652 9.9085C10.0192 9.99324 9.9404 10.0389 9.84845 10.0389H6.49212C6.32792 10.0389 6.20312 10.1692 6.20312 10.3257V11.0296C6.20312 11.1926 6.33449 11.3164 6.49212 11.3164H17.9141C18.0783 11.3164 18.2031 11.1861 18.2031 11.0296V10.3257C18.2031 10.1757 18.0914 10.0584 17.947 10.0389C17.9338 10.0389 17.9207 10.0389 17.9075 10.0389H14.5578C14.4658 10.0389 14.3804 9.99324 14.341 9.9085ZM11.7532 14.0606L9.19163 17.8412C9.13908 17.9259 9.04056 17.9715 8.94861 17.9715H8.09475C7.98966 17.9715 7.89114 17.9128 7.83859 17.8216C7.78605 17.7303 7.79262 17.613 7.85173 17.5283L10.0389 14.3018C10.0915 14.2236 10.098 14.1323 10.0521 14.0476C10.0061 13.9693 9.92726 13.9172 9.83531 13.9172H6.49212C6.33449 13.9172 6.20312 13.7933 6.20312 13.6304V12.9264C6.20312 12.77 6.32792 12.6396 6.49212 12.6396H17.8879C17.8879 12.6396 17.9075 12.6396 17.9141 12.6396C18.0718 12.6396 18.2031 12.7635 18.2031 12.9264V13.6304C18.2031 13.7869 18.0783 13.9172 17.9141 13.9172H14.5643C14.4658 13.9172 14.387 13.9628 14.3476 14.0476C14.3017 14.1323 14.3082 14.2236 14.3608 14.2952L16.5545 17.5283C16.607 17.613 16.6202 17.7238 16.5676 17.8216C16.5151 17.9194 16.4166 17.978 16.3115 17.978H15.4576C15.3591 17.978 15.2737 17.9324 15.2212 17.8542L12.6596 14.0736C12.5939 13.9759 12.4888 13.9237 12.3706 13.9237H12.0488C11.9305 13.9237 11.8255 13.9824 11.7598 14.0736L11.7532 14.0606Z"
        fill="white"
      />
    </svg>
  );
}
