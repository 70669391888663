import type { SVGProps } from "react";

export function Hyperliquid(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="hyperliquid-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#072723" />
      <path
        d="M19.9994 11.9422C20.014 13.2652 19.7391 14.5295 19.1992 15.7373C18.4281 17.4573 16.5794 18.8636 14.8912 17.3666C13.5144 16.1465 13.259 13.6695 11.1963 13.3069C8.46698 12.9737 8.4013 16.1612 6.61828 16.5214C4.63094 16.9281 3.97173 13.5617 4.00092 12.0328C4.03011 10.504 4.43391 8.3553 6.16098 8.3553C8.14833 8.3553 8.28211 11.386 10.8046 11.2219C13.3028 11.0504 13.3466 7.89713 14.9788 6.54715C16.3872 5.38092 18.0437 6.23599 18.8732 7.63988C19.6419 8.93841 19.98 10.4623 19.997 11.9422H19.9994Z"
        fill="#97FCE4"
      />
    </svg>
  );
}
