import type { SVGProps } from "react";

export function Kusama(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <circle
        cx="32"
        cy="32"
        r="32"
        className="fill-[#222222] dark:fill-[#F9F9F9]"
      />
      <path
        d="M37.1318 46.6875C37.442 46.4133 37.7541 46.1391 38.1865 45.756C37.6281 45.232 37.1468 44.6461 36.5433 44.2385C35.1595 43.3033 34.1499 42.1464 33.9262 40.4168C32.909 40.3304 31.9464 40.2477 30.7582 40.1463C31.3655 39.8965 31.7904 39.72 32.3356 39.4965C30.8278 39.0157 29.4609 39.2749 28.1185 39.5341C22.8787 40.5463 17.9039 42.4187 12.8972 44.2066C11.4457 44.725 9.83635 44.8095 8.30031 45.0912C8.2627 44.9766 8.2251 44.8621 8.1875 44.7494C8.76469 44.2667 9.34001 43.7841 10.015 43.2188C9.5769 43.1869 9.2836 43.1662 8.75341 43.1268C13.6455 40.5388 18.3326 38.058 23.0197 35.579C23.0159 35.5132 23.0121 35.4456 23.0084 35.3799H20.5304C20.5116 35.2973 20.4928 35.2127 20.474 35.1301C22.0025 34.1291 23.5254 33.1187 25.0615 32.129C28.6374 29.8247 32.2096 27.5109 35.8082 25.2423C36.9701 24.5098 38.1639 23.7962 39.4236 23.2628C41.3902 22.4327 43.0635 21.244 44.6108 19.796C45.3779 19.0786 46.3029 18.5133 47.2016 17.9536C48.623 17.0691 50.1609 16.9827 51.5522 17.8992C52.9942 18.8495 54.3028 20.0063 55.8125 21.1857C54.9665 21.5538 54.3554 21.843 53.7237 22.0872C52.0617 22.7295 51.0257 23.954 50.4091 25.5784C50.0331 26.5719 49.5912 27.5447 49.296 28.5626C48.3692 31.7684 46.1788 33.8117 43.3098 35.286C41.8076 36.056 40.3073 36.8391 38.8596 37.7049C36.7031 38.9932 36.3214 41.3445 37.9007 43.292C38.916 44.5447 40.0534 45.6433 41.6647 46.126C41.9937 46.2236 42.2776 46.4715 42.6423 46.6856H37.1261L37.1318 46.6875Z"
        className="fill-[#F9F9F9] dark:fill-[#222222]"
      />
    </svg>
  );
}
