import type { SVGProps } from "react";

export function EthereumClassic(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={`dark:border dark:border-gray-700 ${props.className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="ethereum-classic-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#131313" />
      <path
        d="M12.1,15.8l5.5-3c-2,3-3.7,5.5-5.5,8.2-1.9-2.7-3.9-5.8-5.6-8.2,2,1,4,2.1,5.6,3ZM12.1,8.2l5.5,3L12.1,3l-5.6,8.1,5.6-2.9h0ZM12.1,14.8l5.3-2.9h0,0,0s0,0,0,0l-5.3-2.8-5.4,2.9h0s0,0,0,0l5.4,2.9Z"
        fill="#3f9"
      />
    </svg>
  );
}
