import type { SVGProps } from "react";

export function XRP(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="xrp-icon"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        className="fill-[#23292F] dark:fill-white"
      />
      <path
        d="M7.75793 7H6.02356L9.63293 10.5962C10.94 11.8979 13.0593 11.8979 14.3673 10.5962L17.9781 7H16.2423L13.5001 9.73184C13.1006 10.1281 12.5621 10.3502 12.0012 10.3502C11.4402 10.3502 10.9018 10.1281 10.5022 9.73184L7.75793 7Z"
        className="fill-white dark:fill-[#23292F]"
      />
      <path
        d="M16.2645 17H18L14.3672 13.3816C13.0601 12.08 10.9409 12.08 9.63281 13.3816L6 17H7.73438L10.5 14.246C10.8995 13.8497 11.438 13.6276 11.9989 13.6276C12.5599 13.6276 13.0984 13.8497 13.4979 14.246L16.2645 17Z"
        className="fill-white dark:fill-[#23292F]"
      />
    </svg>
  );
}
