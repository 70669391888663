import type { SVGProps } from "react";

export function Flow(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <circle cx="32" cy="32" r="32" fill="#00EF8B" />
      <path
        d="M51 22.4132H40.4121C39.4761 22.4132 38.5783 22.7846 37.9158 23.4457C37.2533 24.107 36.8802 25.0039 36.8784 25.9398V27.1198H27.4706V25.9398C27.4741 22.5091 28.8388 19.2198 31.265 16.7939C33.6913 14.368 36.981 13.0035 40.4121 13H51V22.4132Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8779 36.5269H27.4752V27.1202H23.9415C21.3819 27.1202 18.8798 27.8791 16.7516 29.301C14.6233 30.7229 12.9646 32.7437 11.9851 35.1082C11.0056 37.4726 10.7493 40.0745 11.2487 42.5846C11.748 45.0947 12.9806 47.4004 14.7904 49.21C16.6004 51.0197 18.9064 52.252 21.4168 52.7514C23.9271 53.2506 26.5292 52.9944 28.8939 52.0151C31.2587 51.0356 33.2799 49.377 34.7019 47.2492C36.1239 45.1212 36.883 42.6193 36.883 40.0601V36.5334H46.2924V27.1202L36.8784 27.1198L36.8779 36.5269ZM27.4752 40.0601C27.4752 40.759 27.268 41.442 26.8796 42.0232C26.4914 42.6041 25.9395 43.0571 25.2938 43.3244C24.648 43.5919 23.9375 43.6619 23.252 43.5255C22.5666 43.3892 21.937 43.0527 21.4427 42.5585C20.9485 42.0644 20.612 41.4348 20.4757 40.7494C20.3394 40.064 20.4093 39.3536 20.6767 38.708C20.9442 38.0624 21.3971 37.5105 21.9783 37.1222C22.5593 36.7341 23.2426 36.5269 23.9415 36.5269H27.4752V40.0601Z"
        fill="white"
      />
    </svg>
  );
}
