import type { SVGProps } from "react";

export function Tron(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <circle cx="32" cy="32" r="32" fill="#ED0A28" />
      <path
        d="M45.9674 19.6962L15 14L31.2967 55L54 27.3373L45.9674 19.6962ZM45.4734 22.2072L50.2127 26.7096L37.2556 29.056L45.4734 22.2072ZM34.4408 28.5877L20.7839 17.2623L43.1012 21.3685L34.4408 28.5877ZM33.4631 30.5945L31.2401 49.0002L19.235 18.7854L33.4631 30.5945ZM35.5214 31.567L49.8679 28.9685L33.4168 49.0157L35.5214 31.567Z"
        fill="white"
      />
    </svg>
  );
}
