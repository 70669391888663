import type { SVGProps } from "react";

export function Bitcoin(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="bitcoin-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#F7931A" />
      <path
        d="M17.1204 10.4021C17.3593 8.80521 16.1434 7.94675 14.4809 7.37406L15.0202 5.21083L13.7034 4.88267L13.1784 6.9889C12.8322 6.90264 12.4767 6.82125 12.1234 6.74062L12.6522 4.62052L11.3362 4.29236L10.7965 6.45484C10.51 6.38958 10.2287 6.32508 9.95565 6.25719L9.95715 6.25044L8.14121 5.79702L7.79092 7.20342C7.79092 7.20342 8.7679 7.42732 8.74727 7.44119C9.28058 7.57433 9.37697 7.92725 9.36084 8.20703L8.74652 10.6714C8.78328 10.6808 8.83091 10.6943 8.88341 10.7153C8.83953 10.7044 8.79265 10.6924 8.74427 10.6808L7.88318 14.133C7.81792 14.295 7.65253 14.5381 7.27974 14.4458C7.29287 14.4649 6.32264 14.2069 6.32264 14.2069L5.66895 15.7142L7.3825 16.1414C7.70129 16.2213 8.0137 16.3049 8.32123 16.3836L7.77629 18.5716L9.09156 18.8998L9.63124 16.7351C9.99053 16.8326 10.3393 16.9226 10.6806 17.0073L10.1428 19.1619L11.4596 19.4901L12.0045 17.3062C14.2499 17.7312 15.9383 17.5598 16.649 15.5289C17.2217 13.8938 16.6205 12.9505 15.4391 12.3355C16.2995 12.1371 16.9475 11.5711 17.1204 10.4021ZM14.1118 14.621C13.7049 16.2561 10.9518 15.3722 10.0592 15.1505L10.7822 12.2518C11.6748 12.4746 14.5371 12.9157 14.1118 14.621ZM14.5191 10.3785C14.1478 11.8659 11.8564 11.1102 11.113 10.9249L11.7686 8.29591C12.5119 8.48118 14.9058 8.82697 14.5191 10.3785Z"
        fill="white"
      />
    </svg>
  );
}
