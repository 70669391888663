import type { SVGProps } from "react";

export function Ethereum(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={`bg-white border border-primary dark:border-none ${props.className}`}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="ethereum-icon"
    >
      <path
        d="M15.818 2.5L15.6363 3.11502V20.9614L15.818 21.142L24.1362 16.2453L15.818 2.5Z"
        fill="#343434"
      />
      <path d="M15.8182 2.5L7.5 16.2453L15.8182 21.1421V2.5Z" fill="#8C8C8C" />
      <path
        d="M15.8178 22.7105L15.7154 22.8348V29.1921L15.8178 29.4899L24.1409 17.8163L15.8178 22.7105Z"
        fill="#3C3C3B"
      />
      <path
        d="M15.8182 29.4899V22.7105L7.5 17.8163L15.8182 29.4899Z"
        fill="#8C8C8C"
      />
      <path
        d="M15.818 21.1419L24.1361 16.2452L15.818 12.4799V21.1419Z"
        fill="#141414"
      />
      <path
        d="M7.5 16.2452L15.8181 21.1419V12.4799L7.5 16.2452Z"
        fill="#393939"
      />
    </svg>
  );
}
