import type { SVGProps } from "react";

export function Sui(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="sui-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#4DA2FF" />
      <path
        d="M15.3014 10.8389L15.3012 10.8395C15.8748 11.5557 16.2176 12.4627 16.2176 13.4493C16.2176 14.4505 15.8645 15.3699 15.2755 16.091L15.2247 16.153L15.2113 16.0741C15.1998 16.007 15.1864 15.9393 15.1708 15.871C14.876 14.5812 13.9155 13.4752 12.3346 12.5797C11.2671 11.9766 10.6561 11.2505 10.4956 10.4254C10.392 9.89191 10.469 9.35602 10.6179 8.89702C10.7667 8.43818 10.9881 8.05374 11.1762 7.82228L11.7913 7.07336C11.8991 6.94205 12.1009 6.94205 12.2088 7.07336L15.3014 10.8389ZM16.2741 10.0908L12.1524 5.07187C12.0737 4.97604 11.9263 4.97604 11.8476 5.07187L7.72572 10.0906L7.71242 10.1074C6.95391 11.0447 6.5 12.2357 6.5 13.5323C6.5 16.552 8.96238 19 12 19C15.0376 19 17.5 16.552 17.5 13.5323C17.5 12.2357 17.0461 11.0447 16.2876 10.1074L16.2741 10.0908ZM8.71197 10.8227L9.08082 10.3736L9.09196 10.4565C9.10078 10.5221 9.11149 10.5881 9.12418 10.6544C9.36273 11.9007 10.2149 12.9399 11.6396 13.7448C12.8781 14.4466 13.5991 15.2537 13.8069 16.1388C13.8936 16.5082 13.909 16.8717 13.8715 17.1894L13.8691 17.2091L13.8513 17.2178C13.2923 17.4897 12.664 17.6423 11.9999 17.6423C9.67057 17.6423 7.78219 15.7651 7.78219 13.4493C7.78219 12.455 8.1303 11.5414 8.71197 10.8227Z"
        fill="#F7F7F8"
      />
    </svg>
  );
}
