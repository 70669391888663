import type { SVGProps } from "react";

export function Polygon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="polygon-icon"
    >
      <circle cx="10" cy="10" r="10" fill="#8247E5" />
      <path
        d="M13.2741 7.82242C13.0341 7.68472 12.7271 7.68472 12.4555 7.82242L10.5429 8.95561L9.24421 9.67565L7.36701 10.8049C7.12694 10.9426 6.81999 10.9426 6.54844 10.8049L5.08051 9.91174C4.84044 9.77403 4.67121 9.49859 4.67121 9.1917V7.47617C4.67121 7.20074 4.80896 6.92926 5.08051 6.75613L6.54844 5.89836C6.78851 5.76065 7.09546 5.76065 7.36701 5.89836L8.83494 6.79153C9.07501 6.92926 9.24421 7.20467 9.24421 7.51159V8.64478L10.5429 7.88932V6.72465C10.5429 6.44922 10.4052 6.17772 10.1336 6.00459L7.40242 4.39138C7.16236 4.25365 6.8554 4.25365 6.58386 4.39138L3.78574 6.04001C3.51419 6.17772 3.37646 6.45315 3.37646 6.72465V9.94715C3.37646 10.2226 3.51419 10.4941 3.78574 10.6672L6.55236 12.2804C6.79244 12.4181 7.0994 12.4181 7.37094 12.2804L9.24815 11.1826L10.5469 10.4272L12.4241 9.3294C12.6641 9.1917 12.9711 9.1917 13.2427 9.3294L14.7106 10.1872C14.9506 10.3249 15.1199 10.6003 15.1199 10.9072V12.6227C15.1199 12.8982 14.9821 13.1697 14.7106 13.3428L13.2781 14.2006C13.038 14.3383 12.731 14.3383 12.4595 14.2006L10.9876 13.3428C10.7476 13.2051 10.5783 12.9296 10.5783 12.6227V11.525L9.27965 12.2804V13.4136C9.27965 13.689 9.41738 13.9605 9.68892 14.1337L12.4555 15.7469C12.6956 15.8846 13.0026 15.8846 13.2741 15.7469L16.0408 14.1337C16.2808 13.9959 16.45 13.7205 16.45 13.4136V10.1557C16.45 9.88026 16.3123 9.60878 16.0408 9.43563L13.2741 7.82242Z"
        fill="white"
      />
    </svg>
  );
}
