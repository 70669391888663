import type { SVGProps } from "react";

export function Polkadot(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="polkadot-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#E2195E" />
      <path
        d="M19.8004 7.5C20.7004 9.1 20.7004 10.8 19.8004 11.3C18.9004 11.8 17.5004 11 16.6004 9.4C15.7004 7.8 15.7004 6.1 16.6004 5.6C17.5004 5.1 18.9004 5.9 19.8004 7.5ZM4.20039 7.5C3.30039 9.1 3.30039 10.8 4.20039 11.3C5.10039 11.8 6.50039 11 7.40039 9.4C8.30039 7.8 8.30039 6.1 7.40039 5.6C6.50039 5.1 5.10039 5.9 4.20039 7.5ZM19.8004 16.5C18.9004 18.1 17.5004 18.9 16.6004 18.4C15.7004 17.9 15.7004 16.2 16.6004 14.6C17.5004 13 18.9004 12.2 19.8004 12.7C20.7004 13.2 20.7004 14.9 19.8004 16.5ZM4.20039 16.5C5.10039 18.1 6.50039 18.9 7.40039 18.4C8.30039 17.9 8.30039 16.2 7.40039 14.6C6.50039 13 5.10039 12.2 4.20039 12.7C3.30039 13.2 3.30039 14.9 4.20039 16.5ZM12.0004 21C10.2004 21 8.70039 20.1 8.70039 19.1C8.70039 18.1 10.2004 17.3 12.0004 17.3C13.8004 17.3 15.3004 18.2 15.3004 19.2C15.3004 20.2 13.8004 21 12.0004 21ZM12.0004 6.7C10.2004 6.7 8.70039 5.8 8.70039 4.8C8.70039 3.8 10.2004 3 12.0004 3C13.8004 3 15.3004 3.9 15.3004 4.9C15.3004 5.9 13.8004 6.7 12.0004 6.7Z"
        fill="white"
      />
    </svg>
  );
}
