import type { SVGProps } from "react";

export function Avalanche(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="avalanche-icon"
    >
      <circle cx="10" cy="10" r="10" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5229 15.5229 20 10 20C4.47715 20 0 15.5229 0 10C0 4.47715 4.47715 0 10 0C15.5229 0 20 4.47715 20 10ZM7.16628 13.9795H5.22557C4.81776 13.9795 4.61632 13.9795 4.4935 13.9009C4.36084 13.8149 4.27977 13.6724 4.26995 13.5152C4.26257 13.3703 4.3633 13.1934 4.56474 12.8396L9.35662 4.39328C9.56052 4.03461 9.66369 3.85527 9.79389 3.78895C9.93391 3.71771 10.101 3.71771 10.241 3.78895C10.3712 3.85527 10.4744 4.03461 10.6783 4.39328L11.6634 6.1129L11.6684 6.12168C11.8886 6.50646 12.0003 6.70158 12.0491 6.90638C12.1031 7.12993 12.1031 7.36577 12.0491 7.58932C11.9999 7.79567 11.8894 7.99221 11.6658 8.38281L9.14878 12.8323L9.14226 12.8436C8.92059 13.2316 8.80824 13.4282 8.65254 13.5766C8.48304 13.7387 8.27913 13.8566 8.05558 13.923C7.85168 13.9795 7.62322 13.9795 7.16628 13.9795ZM12.0672 13.9795H14.8481C15.2583 13.9795 15.4647 13.9795 15.5876 13.8985C15.7202 13.8125 15.8037 13.6675 15.8112 13.5104C15.8182 13.3702 15.7197 13.2001 15.5266 12.8669C15.52 12.8556 15.5133 12.844 15.5065 12.8323L14.1136 10.4494L14.0977 10.4226C13.902 10.0916 13.8032 9.92442 13.6763 9.85981C13.5364 9.78856 13.3716 9.78856 13.2317 9.85981C13.1039 9.92614 13.0008 10.1006 12.7969 10.4519L11.4089 12.8348L11.4041 12.843C11.2009 13.1937 11.0994 13.369 11.1067 13.5128C11.1165 13.67 11.1976 13.8149 11.3303 13.9009C11.4506 13.9795 11.657 13.9795 12.0672 13.9795Z"
        fill="#E84142"
      />
    </svg>
  );
}
