import type { SVGProps } from "react";

export function InternetComputer(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="internet-computer-icon"
    >
      <circle cx="12" cy="12" r="12" fill="white" />
      <path
        d="M16.628 8C15.6218 8 14.5249 8.51563 13.3655 9.53127C12.8155 10.0125 12.3405 10.5282 11.9843 10.9407C11.9843 10.9407 11.9843 10.9407 11.9874 10.9438V10.9407C11.9874 10.9407 12.5499 11.5532 13.1718 12.2095C13.5062 11.8125 13.9874 11.2719 14.5405 10.7844C15.5718 9.88128 16.2437 9.69066 16.628 9.69066C18.075 9.69066 19.25 10.8375 19.25 12.2469C19.25 13.647 18.0719 14.7939 16.628 14.8032C16.5624 14.8032 16.478 14.7939 16.3718 14.772C16.7937 14.9532 17.2468 15.0845 17.6781 15.0845C20.3281 15.0845 20.8469 13.3563 20.8812 13.2313C20.9594 12.9157 21 12.5844 21 12.2438C21 9.90628 19.0375 8 16.628 8Z"
        fill="url(#paint0_linear_1894_1224)"
      />
      <path
        d="M7.37194 16.4999C8.37821 16.4999 9.4751 15.9842 10.6345 14.9686C11.1845 14.4873 11.6595 13.9717 12.0157 13.5592C12.0157 13.5592 12.0157 13.5592 12.0126 13.5561V13.5592C12.0126 13.5592 11.4501 12.9467 10.8282 12.2904C10.4939 12.6873 10.0126 13.2279 9.45948 13.7154C8.42822 14.6186 7.75632 14.8092 7.37194 14.8092C5.92504 14.8061 4.75002 13.6592 4.75002 12.2498C4.75002 10.8498 5.92816 9.70288 7.37194 9.69351C7.43756 9.69351 7.52193 9.70288 7.62819 9.72476C7.20633 9.54351 6.75317 9.41225 6.32193 9.41225C3.67189 9.41225 3.15625 11.1404 3.11875 11.2623C3.04063 11.581 3 11.9092 3 12.2498C3 14.5936 4.96253 16.4999 7.37194 16.4999Z"
        fill="url(#paint1_linear_1894_1224)"
      />
      <path
        d="M17.6717 15.047C16.3154 15.0126 14.906 13.9439 14.6185 13.6783C13.8748 12.9907 12.1591 11.1313 12.0248 10.9845C10.7685 9.57506 9.06536 8.00004 7.37157 8.00004H7.36532C5.30905 8.00941 3.5809 9.40318 3.11839 11.2626C3.15276 11.1407 3.8309 9.37818 6.31844 9.44069C7.6747 9.47506 9.09035 10.5594 9.38099 10.8251C10.1247 11.5126 11.8404 13.372 11.9748 13.5189C13.231 14.9251 14.9342 16.5002 16.6279 16.5002H16.6342C18.6905 16.4908 20.4217 15.097 20.8811 13.2376C20.8436 13.3595 20.1623 15.1064 17.6717 15.047Z"
        fill="#29ABE2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1894_1224"
          x1="7.72759"
          y1="8.67246"
          x2="13.9238"
          y2="19.3492"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="#F15A24" />
          <stop offset="0.6841" stopColor="#FBB03B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1894_1224"
          x1="16.2723"
          y1="15.828"
          x2="10.0803"
          y2="5.15359"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="#ED1E79" />
          <stop offset="0.8929" stopColor="#522785" />
        </linearGradient>
      </defs>
    </svg>
  );
}
