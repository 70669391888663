import type { SVGProps } from "react";

export function Cosmos(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="cosmos-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#2e3148" />
      <path
        d="M12,19c3.8,0,7-3.1,7-7s-3.1-7-7-7-7,3.1-7,7,3.1,7,7,7Z"
        fill="#1b1e36"
      />
      <path
        d="M16.4,12c3.2-2.2,5.2-4.4,4.7-5.2-.5-.9-3.3-.2-6.9,1.4-.3-3.9-1.2-6.7-2.2-6.7s-1.8,2.8-2.2,6.7c-3.6-1.7-6.4-2.3-6.9-1.5-.5.9,1.5,3,4.7,5.2-3.2,2.2-5.2,4.4-4.7,5.2.5.9,3.3.2,6.9-1.4.3,3.9,1.2,6.7,2.2,6.7s1.8-2.8,2.2-6.7c3.6,1.7,6.4,2.3,6.9,1.5.5-.9-1.5-3-4.7-5.3ZM5.7,9.9c-2.2-1.8-2.4-2.7-2.4-2.7h0c0-.2.1-.3.1-.3.9-.2,2.2.2,2.2.2,1.2.3,2.7.9,4.2,1.6,0,.6,0,1.3,0,1.9-.6.4-1.1.7-1.6,1.1-1.4-1-2.3-1.8-2.3-1.8ZM9.7,12.8c-.4-.3-.8-.5-1.2-.8.4-.3.8-.5,1.2-.8,0,.2,0,.5,0,.7s0,.5,0,.8ZM7.1,16.4c-2.7,1-3.5.7-3.5.7-.2,0-.2-.2-.2-.2.3-.9,1.3-1.8,1.3-1.8.8-.9,2.1-1.9,3.5-2.8.5.3,1.1.7,1.6,1.1,0,.7,0,1.3,0,1.9-1.5.7-2.7,1.1-2.7,1.1ZM13.9,10.4c-.2-.1-.5-.3-.7-.4-.2-.1-.5-.3-.7-.4.5-.3.8-.5.8-.5.2,0,.3-.1.5-.2,0,.6,0,1.1,0,1.5ZM11.9,2.1s0,0,.1,0c0,0,0,0,.1,0,.4.3.8,1.6.8,1.6.5,1.7.7,3.4.8,4.8-.6.3-1.2.6-1.7.9-.6-.3-1.2-.6-1.7-.9.5-4.9,1.3-6.1,1.6-6.4ZM10.2,9.4c0-.2,0-.3,0-.5.4.2.9.4,1.3.7-.2.1-.4.2-.6.4-.2.1-.5.3-.7.4,0-.6,0-1,0-1ZM10.2,13.7c.2.1.4.3.6.4.2.1.5.3.7.4-.4.2-.9.4-1.3.6,0-.5,0-.9,0-1.4ZM13.4,18.5c-.5,2.8-1.2,3.4-1.2,3.4-.1.2-.3,0-.3,0-.6-.7-.9-2-.9-2-.4-1.2-.6-2.8-.7-4.4.6-.3,1.1-.6,1.7-.9.6.3,1.2.6,1.7.9-.2,1.7-.4,2.9-.4,2.9ZM13.8,15.1c-.5-.2-1-.5-1.3-.7.2-.1.5-.3.7-.4.2-.1.5-.3.7-.4,0,.5,0,1,0,1.5ZM13.9,11.5c0,.5,0,1.1,0,1.6-.3.2-.5.3-.5.3-.5.3-.9.5-1.4.8-.3-.2-.5-.3-.5-.3-.4-.2-.9-.5-1.3-.8,0-.8,0-1.6,0-2.2.7-.4,1.3-.8,1.8-1.1.7.4,1.4.8,1.9,1.1,0,.3,0,.5,0,.5ZM20.6,6.9s0,0,.1,0c0,0,0,0,0,.1,0,.5-1,1.5-1,1.5-1.3,1.3-2.6,2.3-3.7,3.1-.5-.4-1.1-.7-1.7-1.1,0-.7,0-1.3,0-1.9,4.5-2,6-1.9,6.4-1.8ZM14.4,11.2c.5.3.8.5.8.5.1.1.3.2.4.3-.5.3-.9.6-1.2.8,0-.3,0-.6,0-.8s0-.5,0-.8ZM20.7,17s0,0,0,0c-.5.2-1.8-.1-1.8-.1-1.7-.5-3.3-1.1-4.6-1.7,0-.6,0-1.3,0-2,.6-.4,1.1-.7,1.6-1.1,4,2.9,4.6,4.2,4.7,4.6,0,0,0,0,0,.1Z"
        fill="#6f7390"
      />
      <path
        d="M12,13.2c.7,0,1.2-.6,1.2-1.2s-.6-1.2-1.2-1.2-1.2.6-1.2,1.2.6,1.2,1.2,1.2ZM17.1,8c.4,0,.7-.3.7-.7s-.3-.7-.7-.7-.7.3-.7.7.3.7.7.7ZM5.3,10.5c.4,0,.7-.3.7-.7s-.3-.7-.7-.7-.7.3-.7.7.3.7.7.7ZM10.5,19.6c.4,0,.7-.3.7-.7s-.3-.7-.7-.7-.7.3-.7.7.3.7.7.7Z"
        fill="#b7b9c8"
      />
    </svg>
  );
}
