import type { SVGProps } from "react";

export function Celo(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={`border border-primary dark:border-none ${props.className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="celo-icon"
    >
      <circle cx="10" cy="10" r="10" fill="#FCFF42" />
      <path
        d="M15 5H5V15H15V11.5091H13.3394C12.7676 12.7833 11.4804 13.6684 10.0078 13.6684C7.9765 13.6684 6.33158 12.0078 6.33158 9.99217C6.32898 7.9765 7.9765 6.33158 10.0078 6.33158C11.5091 6.33158 12.7964 7.24544 13.3681 8.54831H15V5Z"
        fill="black"
      />
    </svg>
  );
}
