import type { SVGProps } from "react";

export function Base(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="base-icon"
    >
      <circle cx="10" cy="10" r="10" fill="#0052FF" />
      <path
        d="M9.98771 17.0554C13.8912 17.0554 17.0556 13.8966 17.0556 9.99989C17.0556 6.10321 13.8912 2.94434 9.98771 2.94434C6.28432 2.94434 3.24619 5.78768 2.94446 9.40683H12.2865V10.593H2.94446C3.24619 14.2121 6.28432 17.0554 9.98771 17.0554Z"
        fill="white"
      />
    </svg>
  );
}
