import type { SVGProps } from "react";

export function Solana(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={`dark:border dark:border-gray-700 ${props.className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="solana-icon"
    >
      <circle cx="10" cy="10" r="10" fill="#000000" />
      <path
        d="M6.24534 12.3525C6.31417 12.2837 6.40878 12.2436 6.50913 12.2436H15.6096C15.7759 12.2436 15.859 12.4443 15.7415 12.5618L13.9437 14.3595C13.875 14.4284 13.7803 14.4685 13.6799 14.4685H4.57952C4.41323 14.4685 4.33007 14.2678 4.44765 14.1502L6.24534 12.3525Z"
        fill="url(#paint0_linear_1203_1196)"
      />
      <path
        d="M6.24538 5.64032C6.31706 5.57153 6.41167 5.53137 6.50913 5.53137H15.6096C15.7759 5.53137 15.8591 5.73208 15.7415 5.84965L13.9437 7.64735C13.875 7.71618 13.7803 7.75633 13.68 7.75633H4.57952C4.41323 7.75633 4.33011 7.55563 4.44765 7.43805L6.24538 5.64032Z"
        fill="url(#paint1_linear_1203_1196)"
      />
      <path
        d="M13.9437 8.97489C13.875 8.90606 13.7803 8.86594 13.6799 8.86594H4.57952C4.41323 8.86594 4.33007 9.06665 4.44765 9.18418L6.24534 10.9819C6.31417 11.0507 6.40878 11.0909 6.50913 11.0909H15.6096C15.7759 11.0909 15.859 10.8902 15.7415 10.7726L13.9437 8.97489Z"
        fill="url(#paint2_linear_1203_1196)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1203_1196"
          x1="14.7402"
          y1="-147.055"
          x2="-14.2561"
          y2="-143.613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1203_1196"
          x1="11.9863"
          y1="-34.9934"
          x2="-17.0101"
          y2="-31.5511"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1203_1196"
          x1="13.3545"
          y1="3.73401"
          x2="7.05628"
          y2="15.7977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
