import type { SVGProps } from "react";

export function Algorand(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <circle
        cx="32"
        cy="32"
        r="32"
        className="fill-[#000000] dark:fill-[#ffffff]"
      />
      <path
        d="M20.2222 47L25.4286 37.9841L30.6349 29L35.8095 19.9841L36.6667 18.5556L37.0476 19.9841L38.6349 25.9206L36.8571 29L31.6508 37.9841L26.4762 47H32.6984L37.9048 37.9841L40.6032 33.3175L41.873 37.9841L44.2857 47H49.873L47.4603 37.9841L45.0476 29L44.4127 26.6825L48.2857 19.9841H42.6349L42.4444 19.3175L40.4762 11.9524L40.2222 11H34.7937L34.6667 11.1905L29.5873 19.9841L24.381 29L19.2063 37.9841L14 47H20.2222Z"
        className="fill-[#ffffff] dark:fill-[#000000]"
      />
    </svg>
  );
}
