import type { SVGProps } from "react";

export function VeChain(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={`bg-white border border-primary dark:border-none ${props.className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="vechain-icon"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="3.96484"
          y1="10.9863"
          x2="16.0352"
          y2="10.9863"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stopColor="#28008C" />
          <stop offset="0.6" stopColor="#00BED7" />
          <stop offset="1" stopColor="#82BE00" />
        </linearGradient>
      </defs>
      <circle className="fill-[#fff]" cx="10" cy="10" r="10" />
      <path
        d="M16.0352 5.48828H14.958C14.6953 5.48828 14.4544 5.6401 14.3411 5.87627L11.5073 11.7731L11.5049 11.7659L10.7506 13.3371L10.753 13.3444L9.9988 14.9156L6.22999 7.06191H7.30472C7.56738 7.06191 7.80836 7.21373 7.92161 7.44989L10.3819 12.5443L11.1362 10.9731L9.15057 6.86189C8.74815 6.02327 7.89992 5.48828 6.96977 5.48828H3.96484L4.71668 7.06191H4.71909L9.24214 16.4844H10.7506L16.0352 5.48828Z"
        fill="url(#linear-gradient)"
      />
    </svg>
  );
}
