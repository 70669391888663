import type { SVGProps } from "react";

export function BNB(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="bnb-icon"
    >
      <circle cx="12" cy="12" r="12" fill="#f0b90b" />
      <path
        d="M16.3,11.3v-1.9s0,0,0,0l-1.6-.9-2.7,1.6-2.7-1.6-1.6.9v1.9l2.7,1.6v3.2l1.6.9,1.6-.9v-3.2l2.7-1.6ZM12,3.8l-4.3,2.5,1.6.9,2.7-1.6,2.7,1.6,1.6-.9-4.3-2.5ZM6.6,15.2v-3.2s-1.6-.9-1.6-.9v5l4.3,2.5v-1.9l-2.7-1.6ZM6.6,10.7v-1.8l1.6-.9-1.6-.9-1.6.9v1.8l1.6.9ZM12,7l-1.6.9,1.6.9,1.6-.9-1.6-.9ZM9.3,13.6l-1.6-.9v1.9l1.6.9v-1.8ZM12,18.4l-1.6-.9v1.8l1.6.9,1.6-.9v-1.8l-1.6.9ZM17.4,7l-1.6.9,1.6.9v1.8l1.6-.9v-1.8l-1.6-.9ZM18.9,11.1l-1.6.9v3.2s-2.7,1.6-2.7,1.6v1.9l4.3-2.5v-5ZM16.2,14.5l-1.6.9v-1.8l1.6-.9v1.9Z"
        fill="white"
      />
    </svg>
  );
}
