import * as BlockchainLogos from "~/components/logos";
import type { BlockchainId } from "~/lib/stablecoins.server";

const globalClasses = "rounded-full h-6 w-6";

type Props = Readonly<{
  chainId: BlockchainId;
  className?: string;
}>;

const blockchainIcon: Record<
  BlockchainId,
  React.ComponentType<{ className: string }>
> = {
  algorand: BlockchainLogos.Algorand,
  aptos: BlockchainLogos.Aptos,
  arbitrum: BlockchainLogos.Arbitrum,
  avalanche: BlockchainLogos.Avalanche,
  base: BlockchainLogos.Base,
  bitcoin: BlockchainLogos.Bitcoin,
  bnb: BlockchainLogos.BNB,
  canton: BlockchainLogos.Canton,
  cardano: BlockchainLogos.Cardano,
  celo: BlockchainLogos.Celo,
  coreum: BlockchainLogos.Coreum,
  cosmos: BlockchainLogos.Cosmos,
  ethereum: BlockchainLogos.Ethereum,
  "ethereum-classic": BlockchainLogos.EthereumClassic,
  flow: BlockchainLogos.Flow,
  hedera: BlockchainLogos.Hedera,
  hyperliquid: BlockchainLogos.Hyperliquid,
  "internet-computer": BlockchainLogos.InternetComputer,
  kusama: BlockchainLogos.Kusama,
  near: BlockchainLogos.NEAR,
  optimism: BlockchainLogos.Optimism,
  polkadot: BlockchainLogos.Polkadot,
  polygon: BlockchainLogos.Polygon,
  solana: BlockchainLogos.Solana,
  stacks: BlockchainLogos.Stacks,
  stellar: BlockchainLogos.Stellar,
  sui: BlockchainLogos.Sui,
  tezos: BlockchainLogos.Tezos,
  ton: BlockchainLogos.TON,
  tron: BlockchainLogos.Tron,
  vechain: BlockchainLogos.VeChain,
  xrp: BlockchainLogos.XRP,
};

export function BlockchainIcon({
  chainId,
  className = "",
}: Props): JSX.Element | null {
  const IconComponent = blockchainIcon[chainId];
  return IconComponent ? (
    <IconComponent className={`${globalClasses} ${className}`} />
  ) : null;
}
